<script lang="ts">
    import { t } from 'svelte-i18n';
</script>
<footer class="p-6 bg-white shadow-md flex flex-col md:flex-row md:items-center md:justify-between">
    <!-- Logo Section -->
    <div class="flex items-center mb-4 md:mb-0">
        <img src="/public/astro.svg" alt="Astrovel Logo" class="logo mr-2" width="40" height="auto">
        <span class="text-base font-semibold text-gray-900">
            Astrovel
        </span>
    </div>

    <!-- Links Section -->
    <ul class="flex flex-wrap justify-center md:justify-start items-center text-sm text-gray-500">
        <li class="mr-4 md:mr-6">
            <a href="https://astrovel.net/about" target="_blank" class="hover:underline hover:text-blue-600 transition duration-300">{$t("About")}</a>
        </li>
        <li class="mr-4 md:mr-6">
            <a href="https://astrovel.net/privacy" class="hover:underline hover:text-blue-600 transition duration-300"> {$t("privacy_policy")}</a>
        </li>
        <li>
            <a href="https://astrovel.net/contact" class="hover:underline hover:text-blue-600 transition duration-300">{$t("contact")}</a>
        </li>
    </ul>

    <!-- Copyright Section -->
    <span class="text-sm text-gray-500 mt-4 md:mt-0">
        © 2023 <a href="#" class="hover:underline text-blue-600">Astrovel</a>. {$t("all_rights_reserved")}
    </span>
</footer>

<style>
    @font-face {
        font-family: 'HelveticaNeueLTStd-Bd';
        src: url('/public/HelveticaNeueLTStd-Bd.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    .logo {
        width: 40px;  /* Adjust the size of the logo */
        height: auto; /* Maintain aspect ratio */
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1.5rem 2rem;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    footer a {
        color: inherit;
        text-decoration: none;
        transition: color 0.3s ease;
    }

    footer a:hover {
        color: #2563eb; /* Blue color on hover */
    }

    footer .text-gray-500 {
        color: #6b7280;
    }

    footer .text-gray-900 {
        color: #111827;
    }

    footer .font-semibold {
        font-weight: 600;
    }

    footer .mr-2 {
        margin-right: 0.5rem;
    }

    footer .mr-4 {
        margin-right: 1rem;
    }

    footer .mr-6 {
        margin-right: 1.5rem;
    }

    footer .mt-4 {
        margin-top: 1rem;
    }

    footer .hover\\:underline:hover {
        text-decoration: underline;
    }
</style>
