<script lang="ts">

    import Cancel from "./views/Cancel.svelte";
    import Calendar from "./views/Calendar.svelte";
    import ErrorComponent from "./views/ErrorComponent.svelte";
    import {currentComponent} from "./stores";
    import * as Sentry from "@sentry/svelte";
    import {onMount} from "svelte";
    import {setupI18n} from "./i18n";


    let code:  string;

    // Function to determine which component to show and extract the code
    function determineComponent() {
        const pathname = window.location.pathname;
        const parts = pathname.split('/').filter(Boolean); // Remove any empty strings due to leading slashes

        // Check the path and determine the component to render
        if (parts[0] === 'cancel' && parts.length === 2) {
            code = parts[1]; // Save the code
            $currentComponent = Cancel;
        } else if (parts.length === 1) {
            code = parts[0]; // Save the code
            $currentComponent = Calendar;
        } else {
            $currentComponent = ErrorComponent; // No valid code or path, show error
        }
    }

    // Run the function on component mount
    determineComponent();


    if (import.meta.env.PROD) {
        Sentry.init({
            dsn: "https://65b0c258874087a4f31b29cbe7879508@o4507903424200704.ingest.us.sentry.io/4507908534632448",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost",  new RegExp(`^${import.meta.env.VITE_BASE_URL}/api`)],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }



    let i18nReady = false;

    onMount(async () => {
        await setupI18n();
        i18nReady = true;
    });
</script>
<!-- Render the appropriate component -->
{#if i18nReady}
    {#if $currentComponent === Cancel}
        <Cancel {code} />
    {:else if $currentComponent === Calendar}
        <Calendar code={code} />
    {:else}
        <ErrorComponent />
    {/if}
{:else}
    <p>Loading...</p>
{/if}