import type {InterviewScheduler} from "../model/InterviewScheduler";
import type {Interview} from "../model/Interview";

export const scheduleInterview = async (date: Date, time: string, locale: string, code: string) => {
    const interviewScheduler: InterviewScheduler = {
        month: (date.getMonth() + 1),
        year: date.getFullYear(),
        day: date.getDate(),
        code: code,
        zone: locale,
        time: time
    }
    return await makeRequest('post',import.meta.env.VITE_POST_SCHEDULE_INTERVIEW, interviewScheduler)
}

export const rescheduleInterview = () =>{

}

export const cancelInterview = () =>{

}

export const getInterviewData = async (code: string): Promise<Interview> => {
    const data = await makeRequest('get', import.meta.env.VITE_GET_INTERVIEW + code);
        return data as Interview;

}

interface ServerError{
    message: string
    code: number
}

const makeRequest = async <T>(method: string, url: string, body: any = undefined, isResponsePageable: boolean = false): Promise<T> => {

    const response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        console.log(response.body)
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (isResponsePageable) {
        const data = await response.json();
        return data.content;
    } else {
        return await response.json();
    }

}