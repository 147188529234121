<script lang="ts">
    import { onMount } from "svelte";
    import { t } from 'svelte-i18n';
    interface TimeZone {
        name: string;
        time: string;
        zones: string[];
    }

    interface TimeZoneGroup {
        [country: string]: TimeZone;
    }

    interface ZoneGroups {
        [region: string]: TimeZoneGroup;
    }

    interface Region {
        region: string;
        countries: TimeZone[];
    }

    let timeZones: Region[] = [];
    let filteredZones: Region[] = [];
    export let locale: string | null;
    let selectedZone: string = "";
    let use24Hour: boolean = false;
    let searchQuery: string = "";

    onMount(() => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const allTimeZones = Intl.supportedValuesOf("timeZone") as string[];
        const zoneGroups: ZoneGroups = groupTimeZones(allTimeZones);
        updateTimeZones(zoneGroups);

        const matchedZone = findMatchingZone(zoneGroups, userTimeZone);
        if (matchedZone) {
            selectedZone = matchedZone;
        } else {
            selectedZone = timeZones[0].countries[0].zones[0];
        }

        filteredZones = [...timeZones];
    });

    function findMatchingZone(zoneGroups: ZoneGroups, userTimeZone: string): string | null {
        for (const region of Object.values(zoneGroups)) {
            for (const country of Object.values(region)) {
                if (country.zones.includes(userTimeZone)) {
                    return country.name;
                }
            }
        }
        return null;
    }

    function groupTimeZones(zones: string[]): ZoneGroups {
        const zoneGroups: ZoneGroups = {};

        zones.forEach(zone => {
            const now = new Date();
            const formatter = new Intl.DateTimeFormat("en-US", {
                timeZone: zone,
                hour: "2-digit",
                minute: "2-digit",
                hour12: !use24Hour,
            });
            const time = formatter.format(now);
            const region = zone.split('/')[0];

            if (!zoneGroups[region]) {
                zoneGroups[region] = {};
            }

            const country = zone.split('/')[1] || zone;

            if (!zoneGroups[region][country]) {
                zoneGroups[region][country] = { name: country, time, zones: [] };
            }

            if (zoneGroups[region][country].time === time) {
                zoneGroups[region][country].zones.push(zone);
            } else {
                zoneGroups[region][zone] = { name: zone, time, zones: [zone] };
            }
        });

        return zoneGroups;
    }

    function updateTimeZones(zoneGroups: ZoneGroups): void {
        timeZones = Object.entries(zoneGroups).map(([region, countries]) => ({
            region,
            countries: Object.values(countries)
        }));
        filterZones(); // Ensure filteredZones are updated too
    }

    function filterZones(): void {
        const query = searchQuery.toLowerCase();
        filteredZones = timeZones.map(region => ({
            ...region,
            countries: region.countries.filter(country =>
                country.name.toLowerCase().includes(query)
            )
        })).filter(region => region.countries.length > 0);

    }

    function removePrefix(str: string): string {
         let value = str.includes('/') ? str.split('/').pop() || '' : str;

         return value.replace("_"," ");
    }

    function selectRegion(region: Region, country: TimeZone, zone: string){
        if(country.zones.length > 1) {
            selectedZone = zone;
            locale = region.region + "/" + country.name + "/" + removePrefix(zone).replace(" ","_");
        }else{
            selectedZone = country.name;
            locale = region.region + "/" + country.name.replace(" ","_");
        }
    }

</script>

<div class=" w-full m-2">
    <div class="form-control flex flex-row dropdown">
        <label class="label flex-1">
            <span class="label-text font-semibold">{$t("Time zone")}</span>
        </label>
        <div class="relative">
            <label class="block w-72">
                <span class="btn bg-gray-400 w-full text-left border-none selected">{removePrefix(selectedZone)}</span>
                <input type="text" placeholder={$t("Search")}
                       bind:value={searchQuery}
                       on:input={filterZones}
                       class="input w-full bg-white absolute top-0 left-0 opacity-0 focus:opacity-100 focus:z-10 transition-opacity duration-200 ease-in-out" />
            </label>
            <div class="overflow-y-auto max-h-60">
                <ul class="p-2 shadow bg-white rounded-md w-full mt-2 text-white h-80 dropdown-content">
                    {#each filteredZones as region}
                        <li class="py-1">
                            <div class="font-bold text-sm text-gray-400 uppercase pl-4">{region.region}</div>
                            <ul class="ml-2 space-y-1">
                                {#each region.countries as country}
                                    {#if country.zones.length > 1}
                                        {#each country.zones as zone}
                                            <li class="py-1 hover:bg-gray-400 hover:text-white rounded px-2  text-gray-400 ">
                                            <button class="text-left truncate flex flex-col" on:click={() => selectRegion(region,country,zone)}>
                                                {removePrefix(zone)}
                                                <span class="text-sm">{country.time}</span>
                                            </button>
                                            </li>
                                        {/each}

                                    {:else}
                                            <li class="py-1 hover:bg-gray-400 hover:text-white rounded px-2  text-gray-400 ">
                                                <button class="text-left truncate flex flex-col" on:click={() => selectRegion(region,country, "")}>
                                                    {removePrefix(country.name)}
                                                    <span class="text-sm">{country.time}</span>
                                                </button>
                                            </li>
                                    {/if}
                                {/each}
                            </ul>
                        </li>
                    {/each}
                </ul>
            </div>
        </div>
    </div>
</div>

<style>
    .dropdown-content {
        max-height: 60vh;
        overflow-y: auto;
        font-size: 0.9rem;
        scrollbar-width: thin;
        scrollbar-color: #3b3b3b #1f1f1f;
    }
    .dropdown-content::-webkit-scrollbar {
        width: 6px;
        height: 6px; /* Add horizontal scrollbar height */
    }
    .dropdown-content::-webkit-scrollbar-thumb {
        background-color: #3b3b3b;
        border-radius: 10px;
    }
    .dropdown-content::-webkit-scrollbar-track {
        background-color: #1f1f1f;
    }
    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .hover\:bg-gray-700:hover {
        background-color: #4b5563;
    }
    .opacity-0 {
        opacity: 0;
    }
    .focus\:opacity-100:focus {
        opacity: 1;
    }

    .selected{
        background-color: #4f46e5;
        color: white;
    }
</style>
