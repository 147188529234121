<script lang="ts">
    import {onMount} from 'svelte';
    import {
        add,
        eachDayOfInterval,
        endOfMonth,
        endOfWeek,
        format,
        isFuture,
        isSameMonth,
        isToday,
        setHours,
        setMinutes,
        startOfMonth,
        startOfWeek,
    } from 'date-fns';
    import {getInterviewData, scheduleInterview} from "../services/InterviewService";
    import type {Interview} from "../model/Interview";
    import {currentComponent, interviewNotFoundCode} from "../stores";
    import InterviewNotFound from "./InterviewNotFound.svelte";
    import {writable} from "svelte/store";
    import Footer from "../lib/Footer.svelte";
    import TimeZoneList from "../lib/TimeZoneList.svelte";
    import { t } from 'svelte-i18n';


    export let code: string
    let currentDate = new Date();
    let selectedDate: Date | null = null;
    let selectedTime: string | null = null;
    let daysInMonth: Date[] = [];
    let startDay: Date;
    let endDay: Date;
    let showModal = false;
    let times: string[] = [];
    let interviewData: Interview | null = null;
    let interviewScheduled = writable(false);
    let loading = writable(false)
    let zone: string | null = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Function to fetch interview data
    async function fetchInterviewData(code: string): Promise<void> {
        try {
            interviewData = await getInterviewData(code)
            generateCalendar(); // Re-generate the calendar with new constraints
        } catch (error) {
            $currentComponent = InterviewNotFound
            $interviewNotFoundCode = code
        }
    }

    function generateCalendar(): void {
        startDay = startOfWeek(startOfMonth(currentDate));
        endDay = endOfWeek(endOfMonth(currentDate));
        daysInMonth = eachDayOfInterval({start: startDay, end: endDay});
    }

    function generateTimes(): void {
        times = [];
        for (let hour = 0; hour < 24; hour++) { // Start at 0 for 12 AM to cover 24 hours
            times.push(format(setMinutes(setHours(new Date(), hour), 0), 'HH:mm'));
            times.push(format(setMinutes(setHours(new Date(), hour), 30), 'HH:mm'));
        }
    }

    function compareTime(timeString: string): boolean{
        if(zone !== null){
            const nowInSelectedZone = new Date().toLocaleString("en-US", { timeZone: zone });
            const currentTimeForNewTimeZone = new Date(nowInSelectedZone);
            const [time, modifier] = timeString.split(' ');
            let [hours, minutes] = time.split(':').map(Number);

            if (modifier === "PM" && hours < 12) {
                hours += 12;
            }
            if (modifier === "AM" && hours === 12) {
                hours = 0;
            }

            const timeToCheck = new Date(currentTimeForNewTimeZone);
            timeToCheck.setHours(hours, minutes, 0, 0);

            if(selectedDate != null){
                timeToCheck.setDate(selectedDate.getDate());
                timeToCheck.setMonth(selectedDate.getMonth());
            }

            return timeToCheck.getTime() < currentTimeForNewTimeZone.getTime();
        }else{
            return false
        }
    }

    $: zone, generateTimes();
    $: selectedDate, generateTimes();

    onMount(() => {
        fetchInterviewData(code);
        generateCalendar();
    });

    function navigateMonth(direction: number): void {
        currentDate = add(currentDate, {months: direction});
        generateCalendar();
    }

    function selectDate(date: Date): void {
        if (isDateSelectable(date)) {
            selectedDate = date;
            showModal = true; // This line might not be necessary if showModal is only used to control the modal visibility through Svelte.
            document.getElementById('open_time_selection_modal').click(); // Simulate the button click to open the modal
        }
    }


    function isDateSelectable(date: Date): boolean {
        return (isToday(date) || isFuture(date));
    }

    function closeTimeSelection(): void {
        showModal = false;
        selectedTime = null;
    }

    function selectTime(time: string): void {
        selectedTime = time;
        showModal = false;
    }

    function formatDate(date: Date | null): string {
        if (!date) return "";
        let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return date.toLocaleDateString('en-US', options);
    }

    async function scheduleAnInterview(): Promise<void> {
        if (selectedDate && selectedTime) {
            try {
                $loading = true
                await scheduleInterview(selectedDate, selectedTime, zone, code);
                $interviewScheduled = true; // Update state to indicate the interview is scheduled
                document.getElementById('time_selection_modal').close();
            } catch (error) {
                console.error('Error scheduling interview:', error);
                // Handle error state appropriately
            }finally {
                $loading = false
            }
        }
    }
</script>


<div class="flex flex-col min-h-screen">
    <header class="bg-white shadow p-4 text-center">
        <h1 class="text-2xl font-bold text-gray-800">
            {#if $interviewScheduled}
                {$t("thank_you_scheduling")}
            {:else}
                {$t("schedule_your_interview")}
            {/if}
        </h1>
        <p class="text-gray-600">
            {#if $interviewScheduled}
                {$t("confirmation_email")}
            {:else}
                {$t("select_date_time")}
            {/if}
        </p>
    </header>


    {#if !$interviewScheduled}
        <div class="flex-grow flex items-center justify-center p-4">

            <button id="open_time_selection_modal" onclick="time_selection_modal.showModal()"></button>

            <div class="bg-white rounded-lg shadow-lg w-full max-w-4xl">
                <div class="flex items-center justify-between p-4">
                    <button on:click={() => navigateMonth(-1)} class="focus:outline-none"
                            disabled={currentDate <= new Date()}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                        </svg>
                    </button>
                    <h2 class="text-xl font-semibold text-gray-800">
                        {format(currentDate, 'MMMM yyyy')}
                    </h2>
                    <button on:click={() => navigateMonth(1)} class="focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                        </svg>
                    </button>
                </div>

                <div class="grid grid-cols-7 divide-x">
                    <div class="text-center py-2 font-medium text-sm">{$t("days.sun")}</div>
                    <div class="text-center py-2 font-medium text-sm">{$t("days.mon")}</div>
                    <div class="text-center py-2 font-medium text-sm">{$t("days.tue")}</div>
                    <div class="text-center py-2 font-medium text-sm">{$t("days.wed")}</div>
                    <div class="text-center py-2 font-medium text-sm">{$t("days.thu")}</div>
                    <div class="text-center py-2 font-medium text-sm">{$t("days.fri")}</div>
                    <div class="text-center py-2 font-medium text-sm">{$t("days.sat")}</div>
                </div>

                <div class="grid grid-cols-7 divide-x divide-y">
                    {#each daysInMonth as day (day.toISOString())}
                        <div
                                class="text-center p-2 cursor-pointer date-box"
                                class:unavailable={!isDateSelectable(day)}
                                class:selected={selectedDate && isSameMonth(day, currentDate) && day.getDate() === selectedDate.getDate()}
                                on:click={() => selectDate(day)}
                        >
                            {format(day, 'd')}
                        </div>
                    {/each}
                </div>
            </div>
        </div>
    {/if}
    <!-- Interview Details Section -->
    <div class="">
        <div class="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        {$t("interview_details")}
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        {$t("prepare_opportunity")}
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    {#if interviewData}
                        <dl>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    {$t("position")}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {interviewData.position}
                                </dd>
                            </div>
                            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    {$t("company")}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {interviewData.company}
                                </dd>
                            </div>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    {$t("location")}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <a href="">interview.astrovel.net/{code}</a>
                                </dd>
                            </div>
                            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    {$t("day")}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {formatDate(selectedDate)}
                                </dd>
                            </div>
                            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    {$t("time")}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {selectedTime}
                                </dd>
                            </div>
                        </dl>
                    {/if}
                </div>
            </div>
        </div>
    </div>

    <Footer/>
</div>

<dialog id="time_selection_modal" class="modal fixed inset-0 flex items-center justify-center z-10">
    {#if $loading}
        <span class="loading loading-spinner loading-lg"></span>
    {:else}
        <div class="modal-box bg-white p-6 rounded-lg shadow-lg overflow-auto max-h-screen">
            <h3 class="font-bold text-lg">{$t("select_time_for")} {formatDate(selectedDate)}</h3>
            <p class="py-4">{$t("close_instruction")}</p>
            <TimeZoneList bind:locale={zone}/>
            <div class="h-64 overflow-auto">
                {#each times as time}
                    {#if !compareTime(time)}
                        <button
                                type="button"
                                class="block w-full text-left px-4 py-2 hover:bg-blue-100 focus:bg-blue-200"
                                class:unavailable={compareTime(time)}
                                disabled={compareTime(time)}
                                on:click={() => selectTime(time)}
                        >
                            {time}
                        </button>
                    {/if}
                {/each}
            </div>

            {#if selectedTime !== null}
                <h2 class="font-bold text-lg m-1">{$t("you_selected")} {formatDate(selectedDate)} {$t("at")} {selectedTime}</h2>
            {/if}

            <button class="btn btn-success mt-2" on:click={()=>scheduleAnInterview()}>{$t("schedule_interview")}</button>
            <form method="dialog" class="modal-backdrop">
                <button type="submit" class="hidden">{$t("close")}</button>
            </form>
        </div>
    {/if}
</dialog>


<style>
    /* Base style for all dates */
    .date-box {
        background-color: #9ca3af; /* Darker color for available dates */
        color: white;
        transition: background-color 0.3s; /* Smooth transition for hover effect */
    }

    /* Style for selected date */
    .selected {
        background-color: #4f46e5;
        color: white;
    }

    /* Style for unavailable dates */
    .unavailable {
        background-color: #f3f4f6; /* Lighter background for unavailable dates */
        color: gray;
    }

    /* Hover effect for available dates */
    .date-box:hover:not(.unavailable):not(.selected) {
        background-color: #4f46e5; /* Darker color for hover on available dates */
        color: white;
    }
</style>
